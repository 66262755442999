import {t} from '@lingui/macro'
import {Link as RouterLink} from 'react-router-dom'

import {Link, Modal, tokens} from '@pleo-io/telescope'
import {BirdhouseClock, Headset, Smiles} from '@pleo-io/telescope-pictograms'

import {exhaustiveCheck} from '@product-web/utils'
import {ContactSupport} from '@product-web-features/ui-contact-support'

export type ModalVariant =
    | 'DOWNGRADE_TO_STARTER_USER_LIMIT'
    | 'DOWNGRADE_FROM_ANNUAL'
    | 'CHANGE_FROM_CUSTOM'

type PlanChangeClarificationModal = {
    isOpen: boolean
    onDismiss: () => void
    variant: ModalVariant
}

export const PlanChangeClarificationModal = ({
    isOpen,
    onDismiss,
    variant,
}: PlanChangeClarificationModal) => {
    const {illustration, title, description, ctaText} = getModalContent(variant)

    const isDowngradeToStarter = variant === 'DOWNGRADE_TO_STARTER_USER_LIMIT'

    return (
        <Modal
            aria-label={t`Clarification plan change`}
            isOpen={isOpen}
            onDismiss={onDismiss}
            dangerouslySetZIndexValue={tokens.zIndexPopover}
        >
            <Modal.Close onClick={onDismiss} />

            <Modal.Illustration>{illustration}</Modal.Illustration>

            <Modal.Title>{title}</Modal.Title>

            <Modal.Content>{description}</Modal.Content>

            <Modal.Actions>
                {isDowngradeToStarter ? (
                    <Link as={RouterLink} to="/people">
                        {ctaText}
                    </Link>
                ) : (
                    <ContactSupport chatLabel={ctaText} buttonVariant="secondary" />
                )}
            </Modal.Actions>
        </Modal>
    )
}

type ModalContent = {
    illustration: JSX.Element
    title: string
    description: string
    ctaText: string
}

const getModalContent = (variant: ModalVariant): ModalContent => {
    const defaultStyle = {width: '80px'}

    switch (variant) {
        case 'DOWNGRADE_TO_STARTER_USER_LIMIT':
            return {
                illustration: <Smiles css={defaultStyle} />,
                title: t`Remove users to downgrade`,
                description: t`The Starter plan allows a maximum of 3 users. Please review your user list and remove enough users to be able to downgrade to the Starter plan.`,
                ctaText: t`Manage users`,
            }
        case 'DOWNGRADE_FROM_ANNUAL':
            return {
                illustration: <BirdhouseClock css={defaultStyle} />,
                title: t`Contact us for annual plan downgrades`,
                description: t`Since you're on an annual plan, please contact Pleo support or your account executive to make any changes.`,
                ctaText: t`Start a chat`,
            }
        case 'CHANGE_FROM_CUSTOM':
            return {
                illustration: <Headset css={defaultStyle} />,
                title: t`Contact us for custom plan changes`,
                description: t`You're currently on a custom plan. To make any changes, contact Pleo support or your account executive, and we'll assist you.`,
                ctaText: t`Start a chat`,
            }
        default:
            return exhaustiveCheck(variant)
    }
}
