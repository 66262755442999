import React from 'react'

import type {BaseSamlConfiguration} from '@product-web/bff-moons/static/kerberos-v1'
import {invariant} from '@product-web/utils'

import type {SamlConfigResponse} from '../screens/saml-config/saml-config.bff'

export type SamlConfig = Exclude<SamlConfigResponse, null | undefined>

export type SamlConfigContext = {
    readonly samlConfig: SamlConfig | null
    readonly isLoading: boolean
    readonly isError: boolean
}

export const SamlConfigContext = React.createContext<SamlConfigContext>({
    samlConfig: null,
    isLoading: false,
    isError: false,
})

export const useSamlConfigContext = () => {
    const context = React.useContext(SamlConfigContext)
    invariant(context, '`useSamlConfigContext` can only be used within `SamlConfigContext`')
    return context
}

export type SamlConfigMutationsContext = {
    readonly isCreating: boolean
    readonly isUpdating: boolean
    readonly isDisconnecting: boolean
    create: (props: Omit<BaseSamlConfiguration, 'enabled'>) => Promise<void>
    update: (props: BaseSamlConfiguration) => Promise<void>
    disconnect: () => Promise<void>
}

export const SamlConfigMutationsContext = React.createContext<SamlConfigMutationsContext>({
    isCreating: false,
    isUpdating: false,
    isDisconnecting: false,
    create: () => Promise.resolve(undefined),
    update: () => Promise.resolve(undefined),
    disconnect: () => Promise.resolve(undefined),
})

export const useSamlConfigMutationsContext = () => {
    const context = React.useContext(SamlConfigMutationsContext)
    invariant(
        context,
        '`useSamlConfigMutationsContext` must be used within a `SamlConfigMutationsContext`',
    )
    return context
}
