import {t, Trans} from '@lingui/macro'
import type {ReactNode} from 'react'

import {Box, Card, Inline, Stack, Text, tokens} from '@pleo-io/telescope'
import {Check, Close} from '@pleo-io/telescope-icons'
import {Headset} from '@pleo-io/telescope-pictograms'

import type {BillingInfoCurrency, CpqRatePlanType} from '@product-web/bff-moons/generated/beyond'
import {SupportedLanguage} from '@product-web/i18n'
import {breakpoints} from '@product-web/styles/theme'
import {useUser} from '@product-web/user'
import {useMediaQuery} from '@product-web/web-platform/use-media-query'
import {ContactSupport} from '@product-web-features/ui-contact-support'

import {
    getArrayDifference,
    getPlanContentConfiguration,
    renderUpToCashbackLine,
    renderUpToCreditLine,
} from '../pricing-plans.helper'

type PlanChangeSummaryProps = {
    targetPlan: {planType: CpqRatePlanType; isUpgrade: boolean}
    currentPlan: CpqRatePlanType
    companyCurrency: BillingInfoCurrency
    children?: ReactNode
}

export const PlanChangeSummary = ({
    targetPlan,
    currentPlan,
    companyCurrency,
    children,
}: PlanChangeSummaryProps) => {
    const user = useUser()
    const locale = user?.language ?? SupportedLanguage.EN
    const entitlements = getListOfEntitlements({targetPlan, currentPlan, companyCurrency, locale})
    const entitlementsLeft = entitlements.slice(0, Math.ceil(entitlements.length / 2))
    const entitlementsRight = entitlements.slice(Math.ceil(entitlements.length / 2))

    const isMobile = useMediaQuery(`(max-width: ${breakpoints.tabletUp})`)

    return (
        <Stack space={32}>
            <Card padding={32}>
                <Text variant="xlarge-accent" weight="medium">
                    {targetPlan?.isUpgrade
                        ? t`By upgrading, you'll get`
                        : t`By downgrading, you'll lose`}
                </Text>
                <Inline paddingTop={16} paddingBottom={24}>
                    {isMobile && (
                        <Stack space={12}>
                            {entitlements.map((entitlement, index) => (
                                <EntitlementLine
                                    key={`${index}-${entitlement}`}
                                    entitlement={entitlement}
                                    isUpgrade={targetPlan.isUpgrade}
                                />
                            ))}
                        </Stack>
                    )}
                    {!isMobile && (
                        <>
                            <Stack space={12}>
                                {entitlementsLeft.map((entitlement, index) => (
                                    <EntitlementLine
                                        key={`${index}-${entitlement}`}
                                        entitlement={entitlement}
                                        isUpgrade={targetPlan.isUpgrade}
                                    />
                                ))}
                            </Stack>
                            <Stack space={12}>
                                {entitlementsRight.map((entitlement, index) => (
                                    <EntitlementLine
                                        key={`${index}-${entitlement}`}
                                        entitlement={entitlement}
                                        isUpgrade={targetPlan.isUpgrade}
                                    />
                                ))}
                            </Stack>
                        </>
                    )}
                </Inline>
                <PlanChangeHelpSection isMobile={isMobile} />
            </Card>
            {children}
        </Stack>
    )
}

const EntitlementLine = ({entitlement, isUpgrade}: {entitlement: string; isUpgrade: boolean}) => (
    <Inline space={8} alignItems="center" css={{width: '280px'}}>
        {isUpgrade ? (
            <Check size={16} color={tokens.colorContentPositive} />
        ) : (
            <Close size={16} color={tokens.colorContentNegative} />
        )}
        <Text>{entitlement}</Text>
    </Inline>
)

const PlanChangeHelpSection = ({isMobile}: {isMobile: boolean}) => (
    <Box borderTop={tokens.borderPrimary}>
        <Inline paddingTop={24} space={16} alignItems="flex-start" css={{marginTop: 'auto'}}>
            {!isMobile && <Headset css={{width: '64px', height: '64px'}} />}
            <Box>
                <Text>
                    <Trans>Can we help?</Trans>
                </Text>
                <Text
                    variant="small-subtle"
                    css={{
                        paddingTop: tokens.spacing4,
                        paddingBottom: tokens.spacing8,
                        maxWidth: '400px',
                    }}
                >
                    <Trans>
                        If you have any questions around features, add-ons or pricing, just reach
                        out to us and we'll be happy to help.
                    </Trans>
                </Text>
                <ContactSupport chatLabel={t`Chat with us`} />
            </Box>
        </Inline>
    </Box>
)

const getListOfEntitlements = ({
    targetPlan,
    currentPlan,
    companyCurrency,
    locale,
}: PlanChangeSummaryProps & {
    locale: SupportedLanguage
}) => {
    const currentPlanConf = getPlanContentConfiguration(currentPlan)
    const targetPlanConf = getPlanContentConfiguration(targetPlan.planType)

    let cashbackLine = ''
    let creditLine = ''
    const entintlements = getArrayDifference(
        targetPlanConf.allEntitlements,
        currentPlanConf.allEntitlements,
    )

    if (targetPlan.isUpgrade) {
        cashbackLine = renderUpToCashbackLine(targetPlanConf.cashback ?? 0)
        creditLine = renderUpToCreditLine({
            planConfiguration: targetPlanConf,
            currency: companyCurrency,
            locale,
        })
    } else {
        cashbackLine = renderUpToCashbackLine(currentPlanConf.cashback ?? 0)
        creditLine = renderUpToCreditLine({
            planConfiguration: currentPlanConf,
            currency: companyCurrency,
            locale,
        })
    }

    if (creditLine) {
        entintlements.unshift(creditLine)
    }
    if (cashbackLine) {
        entintlements.unshift(cashbackLine)
    }

    return entintlements
}
