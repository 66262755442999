import {t, Trans} from '@lingui/macro'
import {Form, Formik} from 'formik'
import type {Dispatch, SetStateAction} from 'react'

import type {SelectTypes} from '@pleo-io/telescope'
import {
    Button,
    Checkbox,
    FormikCurrencyInput as CurrencyInput,
    FormikInput as Input,
    FormikSelect as Select,
    Inline,
    RadioButton,
    RadioGroup,
    Stack,
} from '@pleo-io/telescope'

import yup from '@product-web/validation/yup'

import type {CalculatePricingInput, CalculatePricingOutput} from '../../index.bff'

interface Props {
    isLoading: boolean
    pricingQuote: CalculatePricingOutput
    setPayload: Dispatch<SetStateAction<CalculatePricingInput>>
}

export const PricingDetailsForm = ({isLoading, pricingQuote, setPayload}: Props) => {
    const {partnerTier, currencyCode, isDach} = pricingQuote

    const partnerTierOptions: SelectTypes.Option[] = [
        {label: t`Trial`, value: 'TRIAL'},
        {label: t`Bronze`, value: 'BRONZE'},
        {label: t`Silver`, value: 'SILVER'},
        ...(isDach
            ? [
                  {label: t`Gold`, value: 'GOLD_DACH'},
                  {label: t`Platinum`, value: 'PLATINUM_DACH'},
              ]
            : [
                  {label: t`Gold`, value: 'GOLD'},
                  {label: t`Platinum`, value: 'PLATINUM'},
              ]),
    ]

    const currencyCodeOptions: SelectTypes.Option[] = [
        {label: 'EUR', value: 'EUR'},
        {label: 'DKK', value: 'DKK'},
        {label: 'GBP', value: 'GBP'},
        {label: 'NOK', value: 'NOK'},
        {label: 'SEK', value: 'SEK'},
        {label: 'USD', value: 'USD'},
    ]

    const planOptions: SelectTypes.Option[] = [
        {label: t`Starter`, value: 'STARTER'},
        {label: t`Essential`, value: 'ESSENTIAL'},
        {label: t`Advanced`, value: 'ADVANCED'},
        {label: t`Beyond`, value: 'BEYOND'},
    ]

    const initialValues = {
        partnerTier:
            partnerTierOptions.find((option) => option.value === partnerTier) ||
            partnerTierOptions[1],
        plan: planOptions[0],
        usersCount: 3,
        billingFrequency: 'MONTHLY',
        currencyCode:
            currencyCodeOptions.find((option) => option.value === currencyCode) ||
            currencyCodeOptions[0],
        cashback: false,
        expectedMonthlySpend: 0,
    }

    const validationSchema = yup.object().shape({
        partnerTier: yup.string().required(),
        plan: yup.string().required(),
        usersCount: yup
            .number()
            .required()
            .min(1, t`At least 1 user is required`),
        billingFrequency: yup.string().required(),
        currencyCode: yup.string().required(),
        cashback: yup.boolean().required(),
        expectedMonthlySpend: yup.number().when('cashback', {
            is: true,
            then: yup
                .number()
                .required()
                .min(0, t`Please enter a positive amount`),
            otherwise: yup.number().notRequired(),
        }),
    })

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                const payload = {
                    partnerTier: values.partnerTier.value,
                    plan: values.plan.value,
                    usersCount: Number(values.usersCount),
                    billingFrequency: values.billingFrequency,
                    currencyCode: values.currencyCode.value,
                    cashback: values.cashback,
                    expectedMonthlySpend: Number(values.expectedMonthlySpend),
                    isDach,
                }
                setPayload(payload)
            }}
        >
            {({values, handleSubmit, setValues}) => (
                <Form onSubmit={handleSubmit}>
                    <Stack space={24}>
                        <Select
                            label={t`Your partner tier`}
                            name="partnerTier"
                            value={partnerTierOptions.find(
                                (option) => option === values.partnerTier,
                            )}
                            options={partnerTierOptions}
                            portalled
                        />
                        <Input
                            label={t`Total number of users`}
                            name="usersCount"
                            value={values.usersCount}
                        />
                        <RadioGroup
                            label={t`Billing frequency`}
                            defaultValue="MONTHLY"
                            onValueChange={(value) => {
                                setValues({...values, billingFrequency: value})
                            }}
                        >
                            <Inline space={24}>
                                <RadioButton value="MONTHLY" label={t`Monthly`} />
                                <RadioButton value="ANNUALLY" label={t`Annually`} />
                            </Inline>
                        </RadioGroup>
                        <Select
                            label={t`Currency`}
                            name="currencyCode"
                            value={currencyCodeOptions.find(
                                (option) => option === values.currencyCode,
                            )}
                            options={currencyCodeOptions}
                            portalled
                        />
                        <Select
                            label={t`Pricing Plan`}
                            name="plan"
                            value={planOptions.find((option) => option === values.plan)}
                            options={planOptions}
                            portalled
                        />
                        <Checkbox
                            label={t`Cashback`}
                            name="cashback"
                            checked={values.cashback}
                            onChange={(e) => setValues({...values, cashback: e.target.checked})}
                        >
                            <Trans>My client receives cashback</Trans>
                        </Checkbox>
                        {values.cashback && (
                            <CurrencyInput
                                label={t`Expected monthly spend`}
                                name="expectedMonthlySpend"
                                onChange={(e) =>
                                    setValues({
                                        ...values,
                                        expectedMonthlySpend: Number(e.target.value),
                                    })
                                }
                                value={values.expectedMonthlySpend}
                                currency={values.currencyCode.value}
                                postfix={values.currencyCode.value}
                            />
                        )}
                        <Button variant="primary" type="submit" loading={isLoading}>
                            <Trans>Calculate pricing</Trans>
                        </Button>
                    </Stack>
                </Form>
            )}
        </Formik>
    )
}
