import * as React from 'react'
import {Outlet, Route} from 'react-router-dom'

import {roleSets} from '@product-web/user'

import {
    InstalledApplicationAPIContext,
    InstalledApplicationContext,
} from './contexts/active-installation'
import {SamlConfigContext, SamlConfigMutationsContext} from './contexts/saml-config'
import {SamlEntitlementContext} from './contexts/saml-entitlement-context'
import {useInstalledApplicationBackend} from './use-installed-application-backend'
import {
    useSamlConfig,
    useSamlConfigMutations,
    useSamlFeatureEntitlement,
} from './use-saml-config-backend'

const RouteMapper = React.lazy(async () => import('./components/route-mapper'))
const IntegrationsTabs = React.lazy(async () => import('./components/integrations-tabs'))
const PeopleManagementIntegration = React.lazy(
    async () => import('./screens/people-management-integration'),
)
const SamlConfigIntegration = React.lazy(async () => import('./screens/saml-config-integration'))

const WithActivePeopleIntegrationContext = () => {
    const {connect, disconnect, ...app} = useInstalledApplicationBackend()

    return (
        <InstalledApplicationContext.Provider value={app}>
            <InstalledApplicationAPIContext.Provider value={{connect, disconnect}}>
                <Outlet />
            </InstalledApplicationAPIContext.Provider>
        </InstalledApplicationContext.Provider>
    )
}

const WithActiveSamlConfigContext = () => {
    const samlConfig = useSamlConfig()
    const samlConfigMutations = useSamlConfigMutations()
    const samlFeatureEntitlement = useSamlFeatureEntitlement()

    return (
        <SamlEntitlementContext.Provider value={samlFeatureEntitlement}>
            <SamlConfigContext.Provider value={samlConfig}>
                <SamlConfigMutationsContext.Provider value={samlConfigMutations}>
                    <Outlet />
                </SamlConfigMutationsContext.Provider>
            </SamlConfigContext.Provider>
        </SamlEntitlementContext.Provider>
    )
}

export const settingsRoutes = (
    <Route handle={{auth: true, allowedRoles: roleSets.ownerAndBookkeeperExtended}}>
        <Route index element={<RouteMapper />} />
        <Route path="receipt-tracking" element={<IntegrationsTabs tab="receipt-tracking" />} />
        <Route path="people-management" element={<WithActivePeopleIntegrationContext />}>
            <Route index element={<IntegrationsTabs tab="people-management" />} />
            <Route path=":integration" element={<PeopleManagementIntegration />} />
        </Route>
        <Route path="saml-config" element={<WithActiveSamlConfigContext />}>
            <Route index element={<IntegrationsTabs tab="saml-config" />} />
            <Route path=":integration" element={<SamlConfigIntegration />} />
        </Route>
    </Route>
)
