import {lazy, Suspense} from 'react'
import {Route} from 'react-router-dom'
import {useGate} from 'statsig-react'

import {LoadingPage} from '@pleo-io/telescope'

import {roleSets} from '@product-web/user'

const RecurringVendorExpenseDetails = lazy(
    async () =>
        import('./screens/recurring-vendor-expense-details/recurring-vendor-expense-details'),
)
const RecurringVendorDetails = lazy(
    async () => import('./screens/recurring-vendor-details/recurring-vendor-details'),
)
export const RecurringVendorsPage = lazy(
    async () => import('./screens/recurring-vendors/recurring-vendors'),
)
const VendorCardDetails = lazy(
    async () => import('./screens/vendor-card-details/vendor-card-details'),
)

const SetupPage = lazy(async () => import('./components/spend-activation-guide/setup-page'))

const SetupOrRecurringVendors = () => {
    const {value: showGate, isLoading} = useGate('spend_activation_setup_flow')

    const showSetupPage = showGate && !isLoading

    return (
        <Suspense fallback={<LoadingPage />}>
            {showSetupPage ? <SetupPage /> : <RecurringVendorsPage />}
        </Suspense>
    )
}

export const RECURRING_VENDORS_ROUTE = 'recurring-vendors'

export const routes = (
    <Route handle={{auth: true, allowedRoles: roleSets.ownerAndBookkeeper}}>
        <Route index element={<RecurringVendorsPage />} />
        <Route path="setup-guide" element={<SetupOrRecurringVendors />} />
        <Route path=":recurringVendorId" element={<RecurringVendorsPage />}>
            <Route
                path=":expenseId"
                element={
                    <Suspense fallback={<LoadingPage />}>
                        <RecurringVendorExpenseDetails />
                    </Suspense>
                }
            />
            <Route
                path="vendor-cards/:cardId"
                element={
                    <Suspense fallback={<LoadingPage />}>
                        <VendorCardDetails />
                    </Suspense>
                }
            >
                <Route
                    path=":expenseId"
                    element={
                        <Suspense fallback={<LoadingPage />}>
                            <VendorCardDetails />
                        </Suspense>
                    }
                />
            </Route>
            <Route
                index
                element={
                    <Suspense fallback={<LoadingPage />}>
                        <RecurringVendorDetails />
                    </Suspense>
                }
            />
        </Route>
    </Route>
)
