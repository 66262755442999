import {useExperiment} from 'statsig-react'

import {useUser} from '@product-web/user'

import {bff} from './bff-hooks'

export const useCompanyOnboardingStatus = () => {
    const user = useUser()
    const experiment = useExperiment('get_started_screen_variants_h1_2024')
    const experimentVariant = experiment.config.get('variant', 'control')

    const isOwner = user?.role === 'owner'

    const {data} = bff.featureOnboarding.companyOnboarding.getCompanyOnboardingStatus.useQuery(
        {
            experimentVariant,
        },
        {
            enabled: isOwner && !experiment.isLoading,
        },
    )

    if (!isOwner) {
        return {
            awaitingCompanyOnboardingStatus: false,
            hasFinishedCompanyOnboarding: true,
            shouldHideCompanyOnboarding: true,
        }
    }

    return {
        awaitingCompanyOnboardingStatus: !data,
        hasFinishedCompanyOnboarding: data?.hasFinishedCompanyOnboarding,
        shouldHideCompanyOnboarding: data?.shouldHideCompanyOnboarding,
    }
}
