/* eslint-disable string-to-lingui/text-restrictions */
import {plural, t, Trans} from '@lingui/macro'
import {useEffect} from 'react'
import styled from 'styled-components'

import {Button, Callout, Modal, Stack, Text} from '@pleo-io/telescope'
import {ArrowRight} from '@pleo-io/telescope-icons'

import * as tracking from '@product-web/analytics'
import {PlanTypeName} from '@product-web/api-types/billing'
import {dayjs} from '@product-web/dates/dayjs'
import {reportError} from '@product-web/error/report'

import HighFive from './high-five.svg'

interface TrialStartedModalProps {
    closeModal: () => void
    isOpen: boolean
    trialDaysRemaining: number
    trialEndDate?: string
    preSelectedPlatformPlan?: string
}

export const TrialStartedModal = ({
    isOpen,
    closeModal,
    trialDaysRemaining,
    trialEndDate,
    preSelectedPlatformPlan,
}: TrialStartedModalProps) => {
    const onClose = () => {
        tracking.companyOnboardingNextStepsModalActioned({cta: 'close-button'})
        closeModal()
    }

    useEffect(() => {
        if (isOpen) {
            if (trialDaysRemaining > 0) {
                tracking.companyOnboardingNextStepsModalViewed()
            } else {
                reportError(`Invalid trialDaysRemaining value: ${trialDaysRemaining}`)
            }
        }
    }, [isOpen, trialDaysRemaining])

    if (trialDaysRemaining <= 0) {
        return null
    }

    return (
        <Modal isOpen={isOpen} onDismiss={onClose} aria-label={t`Your Pleo account is live!`}>
            <Modal.Close onClick={onClose} />
            <Modal.Illustration>
                <Illustration src={HighFive} alt="" />
            </Modal.Illustration>
            <Modal.Title>
                <Trans>Your Pleo account is live!</Trans>
            </Modal.Title>
            <Modal.Content>
                <Stack space={24}>
                    <Text>
                        <Trans>
                            Get a head start on expenses with free access to Pleo Essential for the
                            next{' '}
                            {trialDaysRemaining && trialDaysRemaining > 0
                                ? plural(trialDaysRemaining, {
                                      one: '# day',
                                      other: '# days',
                                  })
                                : undefined}
                            .
                        </Trans>
                    </Text>
                    {!!trialEndDate && (
                        <Callout>
                            <Callout.Content>
                                <Callout.ContentHeading align="left">
                                    <Trans>What happens next?</Trans>
                                </Callout.ContentHeading>
                                <Callout.Text>
                                    {getCalloutText({
                                        trialEndDate,
                                        preSelectedPlatformPlan,
                                    })}
                                </Callout.Text>
                            </Callout.Content>
                        </Callout>
                    )}
                </Stack>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    variant="primary"
                    IconRight={ArrowRight}
                    onClick={() => {
                        tracking.companyOnboardingNextStepsModalActioned({
                            cta: 'explore-pleo-button',
                        })
                        closeModal()
                    }}
                >
                    <Trans>Explore Pleo</Trans>
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

const getCalloutText = ({
    trialEndDate,
    preSelectedPlatformPlan,
}: {
    trialEndDate: string
    preSelectedPlatformPlan?: string
}) => {
    const trialEndDateFull = dayjs(trialEndDate).format('D MMMM YYYY')
    if (
        preSelectedPlatformPlan &&
        (preSelectedPlatformPlan === 'ESSENTIAL' ||
            preSelectedPlatformPlan === 'ADVANCED' ||
            preSelectedPlatformPlan === 'BEYOND')
    ) {
        return (
            <Trans>
                On {trialEndDateFull}, you'll switch over to{' '}
                <strong>Pleo {PlanTypeName[preSelectedPlatformPlan]}</strong>. You can change your
                plan at any time from the Billing page.
            </Trans>
        )
    }

    return (
        <Trans>
            On {trialEndDateFull}, you'll switch over to{' '}
            <strong>Pleo {PlanTypeName.STARTER}</strong> if you have 3 users or less. You can change
            your plan at any time from the Billing page.
        </Trans>
    )
}

const Illustration = styled.img`
    width: 100%;
    max-width: 320px;
`
