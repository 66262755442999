import {t, Trans} from '@lingui/macro'
import {useState} from 'react'
import Helmet from 'react-helmet'

import {Accordion, Box, Loading} from '@pleo-io/telescope'

import {useFlags} from '@product-web/flags'
import PageNotFound from '@product-web/routes/page-not-found/page-not-found'
import {useToaster} from '@product-web/toaster'
import {Page} from '@product-web-features/ui-page/page'

import {PricingDetailsForm} from './pricing-details-form'

import {bff} from '../../bff-hooks'
import {SplitContent} from '../../components/split-content'
import type {CalculatePricingInput, CalculatePricingOutput} from '../../index.bff'

export const PricingCalculator = () => {
    const {partnerPricingCalculator} = useFlags()
    const {showToast} = useToaster()
    const defaultPayload = {
        plan: 'STARTER' as const,
        usersCount: 3,
        billingFrequency: 'MONTHLY' as const,
        cashback: false,
        expectedMonthlySpend: 0,
    }
    const [payload, setPayload] = useState<CalculatePricingInput>(defaultPayload)
    const [pricingQuote, setPricingQuote] = useState<CalculatePricingOutput | null>(null)

    const {data, isLoading} = bff.pricingCalculator.calculatePricing.useQuery(payload, {
        onSuccess: setPricingQuote,
        onError: () => {
            showToast(t`Please try again, or contact support.`, {
                title: t`Something went wrong`,
                level: 'error',
            })
        },
    })

    // TODO replace with results table component - https://linear.app/pleo/issue/PAR-2741
    // eslint-disable-next-line no-console
    console.log('PRICING_CALCULATION:', data)

    if (!partnerPricingCalculator) {
        return <PageNotFound />
    }

    return (
        <Page layout="wide" showNav>
            <Helmet>
                <title>{t`Pleo Pricing Calculator`}</title>
            </Helmet>
            <Page.Header>
                <Page.SpaceBetween>
                    <Page.Title>
                        <Trans>Pleo Pricing Calculator</Trans>
                    </Page.Title>
                </Page.SpaceBetween>
            </Page.Header>
            <SplitContent>
                <SplitContent.Main>{/* TODO Pricing results component here */}</SplitContent.Main>
                <SplitContent.Right>
                    <Accordion defaultValue={['pricing-details']}>
                        <Accordion.Item value="pricing-details">
                            <Accordion.Header headingLevel="h2" heading={t`Pricing details`} />
                            <Accordion.Content>
                                {!pricingQuote ? (
                                    <Loading />
                                ) : (
                                    <PricingDetailsForm
                                        isLoading={isLoading}
                                        pricingQuote={pricingQuote}
                                        setPayload={setPayload}
                                    />
                                )}
                            </Accordion.Content>
                        </Accordion.Item>
                    </Accordion>
                </SplitContent.Right>
            </SplitContent>
            <Box pb={56} />
        </Page>
    )
}
