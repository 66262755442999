import {Trans} from '@lingui/macro'

import {ModalSplit, useModalSplitSteps} from '@pleo-io/telescope'

interface RecurringSpendAnnouncementProps {
    isOpen: boolean
    setShowModal: (show: boolean) => void
}
export const RecurringSpendAnnouncement = ({
    isOpen,
    setShowModal,
}: RecurringSpendAnnouncementProps) => {
    const steps = [
        {stepId: 'manageSpend'},
        {stepId: 'moneyAside'},
        {stepId: 'vendorCards'},
        {stepId: 'setup'},
    ]
    const {activeStep, setActiveStep, nextStep, previousStep} = useModalSplitSteps({
        steps,
        initialActiveStep: 'manageSpend',
    })

    const handleClose = () => {
        setShowModal(false)
        setActiveStep('manageSpend')
    }

    const pinkSlides = ['manageSpend', 'setup']
    const getIllustrationVariant = pinkSlides.includes(activeStep) ? 'pink' : 'gray'

    return (
        <ModalSplit isOpen={isOpen} onDismiss={handleClose}>
            <ModalSplit.Content>
                <ModalSplit.StepIndicator
                    steps={steps}
                    activeStep={activeStep}
                    onClickStep={setActiveStep}
                />
                <ModalSplit.Steps activeStepId={activeStep}>
                    <ModalSplit.Step stepId="manageSpend">
                        <ModalSplit.Body>
                            <ModalSplit.Title>
                                <Trans>A better way to manage your business critical spend</Trans>
                            </ModalSplit.Title>
                            <ModalSplit.Text>
                                <Trans>
                                    Activate our smart features to gain full visibility and tighter
                                    control over your marketing or IT budgets.
                                </Trans>
                            </ModalSplit.Text>
                        </ModalSplit.Body>
                        <ModalSplit.Actions>
                            <ModalSplit.NextButton onClick={nextStep}>
                                <Trans>Continue</Trans>
                            </ModalSplit.NextButton>
                        </ModalSplit.Actions>
                    </ModalSplit.Step>
                    <ModalSplit.Step stepId="moneyAside">
                        <ModalSplit.Body>
                            <ModalSplit.Title>
                                <Trans>Set money aside with dedicated sub-wallets</Trans>
                            </ModalSplit.Title>
                            <ModalSplit.Text>
                                <Trans>
                                    Keep your budget organised and separate by having enough funds
                                    to spend without interruptions and avoiding the risk of
                                    overspending.
                                </Trans>
                            </ModalSplit.Text>
                        </ModalSplit.Body>
                        <ModalSplit.Actions>
                            <ModalSplit.BackButton onClick={previousStep} />
                            <ModalSplit.NextButton onClick={nextStep}>
                                <Trans>Continue</Trans>
                            </ModalSplit.NextButton>
                        </ModalSplit.Actions>
                    </ModalSplit.Step>
                    <ModalSplit.Step stepId="vendorCards">
                        <ModalSplit.Body>
                            <ModalSplit.Title>
                                <Trans>Use vendor cards to keep business expenses in check</Trans>
                            </ModalSplit.Title>
                            <ModalSplit.Text>
                                <Trans>
                                    Assign cards to your go-to suppliers or services, switch card
                                    ownership with just a click, and let automated data entry take
                                    care of bookkeeping for you.
                                </Trans>
                            </ModalSplit.Text>
                        </ModalSplit.Body>
                        <ModalSplit.Actions>
                            <ModalSplit.BackButton onClick={previousStep} />
                            <ModalSplit.NextButton onClick={nextStep}>
                                <Trans>Continue</Trans>
                            </ModalSplit.NextButton>
                        </ModalSplit.Actions>
                    </ModalSplit.Step>
                    <ModalSplit.Step stepId="setup">
                        <ModalSplit.Body>
                            <ModalSplit.Title>
                                <Trans>
                                    Follow our easy steps and master your spend management
                                </Trans>
                            </ModalSplit.Title>
                        </ModalSplit.Body>
                        <ModalSplit.Actions>
                            <ModalSplit.BackButton onClick={previousStep} />
                            <ModalSplit.NextButton onClick={handleClose}>
                                <Trans>Set up now</Trans>
                            </ModalSplit.NextButton>
                        </ModalSplit.Actions>
                    </ModalSplit.Step>
                </ModalSplit.Steps>

                {/* TO BE enabled later */}
                {/* <ModalSplit.Footer>
                    <Trans>Want to do it later?</Trans>{' '}
                    <Link href="">
                        <Trans>Send email reminder</Trans>
                    </Link>
                </ModalSplit.Footer> */}
            </ModalSplit.Content>
            <ModalSplit.IllustrationContainer variant={getIllustrationVariant}>
                <ModalSplit.Steps activeStepId={activeStep}>
                    <ModalSplit.Step stepId="manageSpend">
                        <ModalSplit.Illustration>
                            {/* <img src={reimbursements} alt="" /> */}
                        </ModalSplit.Illustration>
                    </ModalSplit.Step>
                    <ModalSplit.Step stepId="moneyAside">
                        <ModalSplit.Illustration>
                            {/* <img src={mobile} alt="" /> */}
                        </ModalSplit.Illustration>
                    </ModalSplit.Step>
                    <ModalSplit.Step stepId="vendorCards">
                        <ModalSplit.Illustration horizontalAlign="right">
                            {/* <img src={browser} alt="" /> */}
                        </ModalSplit.Illustration>
                    </ModalSplit.Step>
                </ModalSplit.Steps>
            </ModalSplit.IllustrationContainer>
        </ModalSplit>
    )
}
