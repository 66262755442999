import {t, Trans} from '@lingui/macro'

import {Badge, Inline, Link, Text, tokens, Tooltip} from '@pleo-io/telescope'
import {Check, Help, NewTab} from '@pleo-io/telescope-icons'

import type {Amount, BillingInfoCurrency} from '@product-web/bff-moons/generated/beyond'
import config from '@product-web/config'
import {SupportedLanguage} from '@product-web/i18n'
import {breakpoints} from '@product-web/styles/theme'
import {useUser} from '@product-web/user'
import {useMediaQuery} from '@product-web/web-platform/use-media-query'

import {FeatureComparisonTable} from './feature-comparison-table'

import type {PlansData} from '../../index.bff'
import {formatMinorNumberToCurrency} from '../../lib/currency-formatter'
import {getPlanContentConfiguration} from '../pricing-plans.helper'

const paymentFormatOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
}

const overdraftFormatOptions = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
    notation: 'compact' as const,
}

export const FeaturesComparisonPanel = ({
    availablePlansPricing,
    companyCurrency,
}: {
    availablePlansPricing: PlansData['plansPricing']
    companyCurrency: BillingInfoCurrency
}) => {
    const user = useUser()
    const locale = user?.language ?? SupportedLanguage.EN

    const starterPlanPricing = availablePlansPricing[0]
    const essentialPlanPricing = availablePlansPricing[1]
    const advancedPlanPricing = availablePlansPricing[2]
    const beyondPlanPricing = availablePlansPricing[3]

    const starterPlanConf = getPlanContentConfiguration('STARTER')
    const essentialPlanConf = getPlanContentConfiguration('ESSENTIAL')
    const advancedPlanConf = getPlanContentConfiguration('ADVANCED')
    const beyondPlanConf = getPlanContentConfiguration('BEYOND')

    const isSmallScreen = useMediaQuery(`(max-width: ${breakpoints.desktopMedUp})`)

    if (isSmallScreen) {
        return (
            <Inline
                align="center"
                py={24}
                css={{borderTop: tokens.borderPrimary}}
                data-testid="feature-comparison-link"
            >
                <Link
                    href={`${config.endpoints.commercial}/pricing`}
                    IconRight={NewTab}
                    target="_blank"
                    rel="noreferrer"
                >
                    <Trans>Compare all features</Trans>
                </Link>
            </Inline>
        )
    }

    return (
        <>
            <Inline justifyContent="center" paddingTop={60} data-testid="feature-comparison-table">
                <Text variant="2xlarge-accent">
                    <Trans>Compare all features</Trans>
                </Text>
            </Inline>
            <FeatureComparisonTable
                label={t`Team`}
                entries={[
                    [
                        t`Included users`,
                        starterPlanPricing.additionalUserBilling.freeUserLimit,
                        essentialPlanPricing.additionalUserBilling.freeUserLimit,
                        advancedPlanPricing.additionalUserBilling.freeUserLimit,
                        beyondPlanPricing.additionalUserBilling.freeUserLimit,
                    ],
                    [
                        t`Additional users`,
                        <>
                            {renderAdditionalPricePerUnit({
                                pricePerUnit: starterPlanPricing.additionalUserBilling.pricePerUser,
                                locale,
                            })}{' '}
                            <Tooltip content={t`Maximum 1 additional user`}>
                                <span>
                                    <CustomHelp />
                                </span>
                            </Tooltip>
                        </>,
                        renderAdditionalPricePerUnit({
                            pricePerUnit: essentialPlanPricing.additionalUserBilling.pricePerUser,
                            locale,
                        }),
                        renderAdditionalPricePerUnit({
                            pricePerUnit: advancedPlanPricing.additionalUserBilling.pricePerUser,
                            locale,
                        }),
                        renderAdditionalPricePerUnit({
                            pricePerUnit: beyondPlanPricing.additionalUserBilling.pricePerUser,
                            locale,
                        }),
                    ],
                ]}
            />
            <FeatureComparisonTable
                label={t`Cards & expenses`}
                entries={[
                    [
                        t`Commercial cards`,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                    [
                        t`Apple/Google Pay`,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                    [
                        t`Reimbursements`,
                        renderReimbursementInfo({
                            percentage: starterPlanConf.reimbursementsPercentage,
                            value: starterPlanConf.reimbursementsCap?.[companyCurrency],
                            locale,
                            currency: companyCurrency,
                        }),
                        renderReimbursementInfo({
                            percentage: essentialPlanConf.reimbursementsPercentage,
                            value: essentialPlanConf.reimbursementsCap?.[companyCurrency],
                            locale,
                            currency: companyCurrency,
                        }),
                        renderReimbursementInfo({
                            percentage: advancedPlanConf.reimbursementsPercentage,
                            value: advancedPlanConf.reimbursementsCap?.[companyCurrency],
                            locale,
                            currency: companyCurrency,
                        }),
                        renderReimbursementInfo({
                            percentage: beyondPlanConf.reimbursementsPercentage,
                            value: beyondPlanConf.reimbursementsCap?.[companyCurrency],
                            locale,
                            currency: companyCurrency,
                        }),
                    ],
                    [
                        t`Temporary cards`,
                        starterPlanConf.tempCards &&
                            renderPricePerDay(
                                formatMinorNumberToCurrency({
                                    value: starterPlanConf.tempCards[companyCurrency],
                                    locale,
                                    options: {...paymentFormatOptions, currency: companyCurrency},
                                }),
                            ),
                        essentialPlanConf.tempCards &&
                            renderPricePerDay(
                                formatMinorNumberToCurrency({
                                    value: essentialPlanConf.tempCards[companyCurrency],
                                    locale,
                                    options: {...paymentFormatOptions, currency: companyCurrency},
                                }),
                            ),
                        advancedPlanConf.tempCards &&
                            renderPricePerDay(
                                formatMinorNumberToCurrency({
                                    value: advancedPlanConf.tempCards[companyCurrency],
                                    locale,
                                    options: {...paymentFormatOptions, currency: companyCurrency},
                                }),
                            ),
                        beyondPlanConf.tempCards &&
                            renderPricePerDay(
                                formatMinorNumberToCurrency({
                                    value: beyondPlanConf.tempCards[companyCurrency],
                                    locale,
                                    options: {...paymentFormatOptions, currency: companyCurrency},
                                }),
                            ),
                    ],
                    [
                        t`Mileage and per diem`,
                        '',
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                    [
                        t`Bulk reimbursements`,
                        '',
                        '',
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                    [
                        t`ATM withdrawals`,
                        '',
                        '',
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                    [
                        <>
                            <Trans>Cashback</Trans>
                            <Text variant="small-subtle">
                                <Trans>For eligible customers only</Trans>
                            </Text>
                        </>,
                        starterPlanConf.cashback !== undefined
                            ? renderPercentage(starterPlanConf.cashback)
                            : '',
                        essentialPlanConf.cashback !== undefined
                            ? renderPercentage(essentialPlanConf.cashback)
                            : '',
                        advancedPlanConf.cashback !== undefined
                            ? renderPercentage(advancedPlanConf.cashback)
                            : '',
                        beyondPlanConf.cashback !== undefined
                            ? renderPercentage(beyondPlanConf.cashback)
                            : '',
                    ],
                ]}
            />
            <FeatureComparisonTable
                label={t`Accounts payable`}
                entries={[
                    [
                        t`Invoice payments`,
                        renderAdditionalPricePerUnit({
                            pricePerUnit:
                                starterPlanPricing.additionalInvoiceBilling.pricePerInvoice,
                            locale,
                            unit: 'invoice',
                        }),
                        renderAdditionalPricePerUnit({
                            pricePerUnit:
                                essentialPlanPricing.additionalInvoiceBilling.pricePerInvoice,
                            locale,
                            unit: 'invoice',
                        }),
                        renderAdditionalPricePerUnit({
                            pricePerUnit:
                                advancedPlanPricing.additionalInvoiceBilling.pricePerInvoice,
                            locale,
                            unit: 'invoice',
                        }),
                        <>
                            {beyondPlanPricing.additionalInvoiceBilling.freeInvoiceLimit !==
                                undefined &&
                                renderAmountIncluded(
                                    beyondPlanPricing.additionalInvoiceBilling.freeInvoiceLimit,
                                )}
                            &nbsp;
                            <Tooltip
                                content={renderAdditionalPricePerUnit({
                                    pricePerUnit:
                                        beyondPlanPricing.additionalInvoiceBilling.pricePerInvoice,
                                    locale,
                                    unit: 'invoice',
                                    postfix: t`after that.`,
                                })}
                            >
                                <span>
                                    <CustomHelp />
                                </span>
                            </Tooltip>
                        </>,
                    ],
                    [
                        t`Image-to-text (OCR) for invoices`,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                    [
                        t`Invoice approval flows`,
                        '',
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                    [
                        t`Vendor cards for subscriptions`,
                        '1',
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                    [
                        <>
                            <Trans>Purchase orders</Trans>{' '}
                            <Badge variant="discover">
                                <Trans>Coming soon</Trans>
                            </Badge>
                        </>,
                        '',
                        <>
                            <Trans>Add-on</Trans>
                            <Tooltip content={renderAddonTooltipContent()}>
                                <span>
                                    {' '}
                                    <CustomHelp />
                                </span>
                            </Tooltip>
                        </>,
                        <>
                            <Trans>Add-on</Trans>
                            <Tooltip content={renderAddonTooltipContent()}>
                                <span>
                                    {' '}
                                    <CustomHelp />
                                </span>
                            </Tooltip>
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                ]}
            />
            <FeatureComparisonTable
                label={t`Policy & control`}
                entries={[
                    [
                        t`Real-time expenses overview`,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                    [
                        t`Spending limits`,
                        '',
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                    [
                        t`Approval workflows`,
                        '',
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                    [
                        t`Custom approval workflows`,
                        '',
                        '',
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                    [
                        t`ATM restrictions`,
                        '',
                        '',
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                    [
                        t`Vendor locks`,
                        '',
                        '',
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                    [
                        t`Budgets`,
                        '',
                        '',
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                    [
                        t`Subwallets`,
                        '',
                        '',
                        '',
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                    [
                        <>
                            <Trans>Spend guidelines</Trans>{' '}
                            <Badge variant="discover">
                                <Trans>Coming soon</Trans>
                            </Badge>
                        </>,
                        '',
                        '',
                        '',
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                ]}
            />
            <FeatureComparisonTable
                label={t`Insights & analytics`}
                entries={[
                    [
                        t`Spend analytics`,
                        '',
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                    [
                        t`Vendor management`,
                        '',
                        '',
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                    [
                        t`Contract renewal management`,
                        '',
                        '',
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                    [
                        t`Advanced vendor insights`,
                        '',
                        '',
                        '',
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                    [
                        <>
                            <Trans>Advanced spending insights</Trans>{' '}
                            <Badge variant="discover">
                                <Trans>Coming soon</Trans>
                            </Badge>
                        </>,
                        '',
                        '',
                        '',
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                ]}
            />
            <FeatureComparisonTable
                label={t`Wallet`}
                entries={[
                    [
                        <>
                            <Trans>Overdraft</Trans>
                            <Text variant="small-subtle">
                                <Trans>For eligible customers only</Trans>
                            </Text>
                        </>,
                        !!starterPlanConf.overdraft?.[companyCurrency] &&
                            renderUpToPrice(
                                formatMinorNumberToCurrency({
                                    value: starterPlanConf.overdraft[companyCurrency],
                                    locale,
                                    options: {...overdraftFormatOptions, currency: companyCurrency},
                                }),
                            ),
                        !!essentialPlanConf.overdraft?.[companyCurrency] &&
                            renderUpToPrice(
                                formatMinorNumberToCurrency({
                                    value: essentialPlanConf.overdraft[companyCurrency],
                                    locale,
                                    options: {...overdraftFormatOptions, currency: companyCurrency},
                                }),
                            ),
                        !!advancedPlanConf.overdraft?.[companyCurrency] &&
                            renderUpToPrice(
                                formatMinorNumberToCurrency({
                                    value: advancedPlanConf.overdraft[companyCurrency],
                                    locale,
                                    options: {...overdraftFormatOptions, currency: companyCurrency},
                                }),
                            ),
                        !!beyondPlanConf.overdraft?.[companyCurrency] &&
                            renderUpToPrice(
                                formatMinorNumberToCurrency({
                                    value: beyondPlanConf.overdraft[companyCurrency],
                                    locale,
                                    options: {...overdraftFormatOptions, currency: companyCurrency},
                                }),
                            ),
                    ],
                    [
                        t`Transfer rules`,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                    [
                        <>
                            <Trans>Multi-currency accounts</Trans>{' '}
                            <Badge variant="discover">
                                <Trans>Coming soon</Trans>
                            </Badge>
                        </>,
                        '',
                        '',
                        '',
                        <>
                            <Trans>Add-on</Trans>
                        </>,
                    ],
                ]}
            />
            <FeatureComparisonTable
                label={t`Bookkeeping`}
                entries={[
                    [
                        t`Custom exports`,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                    [
                        t`Categories & tags`,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                    [
                        t`Smart categorisation`,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                    [
                        t`Digital receipt capture`,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                    [
                        t`Automatic receipt reminders`,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                    [
                        t`Auto-VAT split`,
                        '',
                        '',
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                    [
                        t`Image-to-text (OCR) for receipts`,
                        '',
                        '',
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                ]}
            />
            <FeatureComparisonTable
                label={t`Organisation`}
                entries={[
                    [
                        t`Multi-entity management`,
                        '',
                        '',
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                    [
                        t`External bookkeeper access`,
                        renderAdditionalPricePerUnit({
                            pricePerUnit: starterPlanPricing.pricePerBookkeeper,
                            locale,
                        }),
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                    [
                        t`Teams management`,
                        '',
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                    [
                        t`Reimbursement-only users`,
                        '',
                        '',
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                    [
                        <>
                            <Trans>Department management</Trans>{' '}
                            <Badge variant="discover">
                                <Trans>Coming soon</Trans>
                            </Badge>
                        </>,
                        '',
                        '',
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                ]}
            />
            <FeatureComparisonTable
                label={t`Integrations`}
                entries={[
                    [
                        t`Accounting integrations`,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                    [
                        t`HRIS integrations`,
                        '',
                        '',
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                    [
                        t`Subscription management`,
                        '',
                        '',
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                ]}
            />
            <FeatureComparisonTable
                label={t`Security`}
                entries={[
                    [
                        t`SSO (Single Sign On)`,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                    [
                        t`SAML`,
                        '',
                        '',
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                    [
                        t`Bank statement`,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                    [
                        t`Transaction statement`,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                    [
                        <>
                            <Trans>User activity log</Trans>{' '}
                            <Badge variant="discover">
                                <Trans>Coming soon</Trans>
                            </Badge>
                        </>,
                        '',
                        '',
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                ]}
            />
            <FeatureComparisonTable
                label={t`Support`}
                entries={[
                    [
                        t`Help Centre & FAQs`,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                    [
                        t`Email support`,
                        '',
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                    [
                        t`Live chat`,
                        '',
                        '',
                        <>
                            <PositiveCheck />
                        </>,
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                    [
                        t`Phone support`,
                        '',
                        '',
                        '',
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                    [
                        t`Assisted onboarding`,
                        '',
                        '',
                        '',
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                    [
                        t`Concierge support`,
                        '',
                        '',
                        '',
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                    [
                        t`Executive business review`,
                        '',
                        '',
                        '',
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                    [
                        t`Customer success manager`,
                        '',
                        '',
                        '',
                        <>
                            <PositiveCheck />
                        </>,
                    ],
                ]}
            />
            <FeatureComparisonTable
                label={t`Foreign exchange fees`}
                entries={[
                    [t`Card payments (FX)`, '2.49%', '1.99%', '1.99%', '1.49%'],
                    [t`Invoice payments (FX)`, '2.49%', '1.99%', '1.99%', '1.49%'],
                ]}
            />
        </>
    )
}

const CustomHelp = () => <Help size={16} color={tokens.colorContentStaticQuiet} />
const PositiveCheck = () => <Check color={tokens.colorContentPositive} />
const renderPercentage = (amount: number) => `${amount}%`

// Helper functions so we send only one string for translation on each case
const renderPricePerMonth = (priceText: string, postFix: string) =>
    postFix ? (
        <Trans>
            {' '}
            {priceText}/month {postFix}
        </Trans>
    ) : (
        <Trans>{priceText}/month </Trans>
    )
const renderPricePerInvoice = (priceText: string, postFix: string) =>
    postFix ? (
        <Trans>
            {priceText}/invoice {postFix}
        </Trans>
    ) : (
        <Trans>{priceText}/invoice</Trans>
    )

const renderPricePerDay = (priceText: string) => <Trans>{priceText}/day</Trans>
const renderUpToPrice = (priceText: string) => <Trans>Up to {priceText}</Trans>
const renderAmountIncluded = (amount: number) => <Trans>{amount} included</Trans>
const renderReimbursements = (percentage: number, cappedPriceText: string) =>
    percentage === 0 ? (
        <Trans>{percentage}% (free)</Trans>
    ) : (
        <Trans>
            {percentage}% (max. {cappedPriceText})
        </Trans>
    )
const renderAddonTooltipContent = () => (
    <Trans>Talk to sales about adding this feature to your plan.</Trans>
)

// Helper functions for not repeating rendering logic
const renderAdditionalPricePerUnit = ({
    pricePerUnit,
    locale,
    unit = 'month',
    postfix = '',
}: {
    pricePerUnit?: Amount
    locale: SupportedLanguage
    unit?: 'month' | 'invoice'
    postfix?: string
}) => {
    const renderPricePerUnit = unit === 'month' ? renderPricePerMonth : renderPricePerInvoice

    if (!pricePerUnit) {
        return ''
    }

    return renderPricePerUnit(
        formatMinorNumberToCurrency({
            value: pricePerUnit?.value,
            locale,
            options: {
                ...paymentFormatOptions,
                currency: pricePerUnit?.currency,
            },
        }),
        postfix,
    )
}

const renderReimbursementInfo = ({
    percentage,
    value = 0,
    locale,
    currency,
}: {
    percentage?: number
    value?: number
    locale: SupportedLanguage
    currency: BillingInfoCurrency
}) =>
    percentage !== undefined &&
    renderReimbursements(
        percentage,
        formatMinorNumberToCurrency({
            value,
            locale,
            options: {...overdraftFormatOptions, currency},
        }),
    )
