import {Trans} from '@lingui/macro'
import type {ReactNode} from 'react'
import {useState} from 'react'

import {Badge, Box, Button, Inline, Text, tokens} from '@pleo-io/telescope'

import type {PlanUpgradeSource} from '@product-web/api-types/plan'
import type {SubscriptionRatePlanType} from '@product-web/bff-moons/generated/beyond'
import {PricingPlanModalWrapper} from '@product-web/paywall/pricing-plan-modal/pricing-plan-modal-wrapper'
import type {PageOrigin} from '@product-web/paywall/types'
import {pxBreakpoints} from '@product-web/styles/theme'
import {useContainerQuery} from '@product-web/telescope-lab/container-queries/container'
import {capitalize} from '@product-web/utils'

import {LandingPagesConfig} from '../landing-pages.helper'

export interface LandingPageTopProps {
    requiredPlan: SubscriptionRatePlanType
    pageOrigin: PageOrigin
    planUpgradeSource: PlanUpgradeSource
    entitledCTA?: ReactNode
    title: string
    subtitle?: string
    isOnTrial?: boolean
    topAction?: ReactNode
}

export const LandingPageTop = ({
    requiredPlan,
    pageOrigin,
    planUpgradeSource,
    entitledCTA,
    title,
    subtitle,
    isOnTrial,
    topAction,
}: LandingPageTopProps) => {
    const [showPlanUpdateModal, setShowPlanUpdateModal] = useState(false)

    const requiredPlanCopy = capitalize(requiredPlan.toLowerCase())

    const landingPageConfig = LandingPagesConfig[pageOrigin]
    const ctaCopy = landingPageConfig?.getCtaCopy()

    const isDesktop = useContainerQuery({
        name: 'main',
        minWidth: pxBreakpoints.tabletMedUp,
    })

    return (
        <Inline
            flexDirection={isDesktop ? 'row' : 'column'}
            justifyContent="space-between"
            css={{gap: `${tokens.spacing24}`}}
        >
            <div>
                <Box paddingBottom={24}>
                    {entitledCTA ? (
                        <Badge variant="positive">
                            {isOnTrial ? (
                                <Trans>Included in your trial</Trans>
                            ) : (
                                <Trans>Included in your plan</Trans>
                            )}
                        </Badge>
                    ) : (
                        <Badge variant="discover">
                            <Trans>{requiredPlanCopy} plan</Trans>
                        </Badge>
                    )}
                </Box>
                <Text variant="4xlarge-accent" weight="medium">
                    {title}
                </Text>
                {subtitle ? (
                    <Box paddingTop={8}>
                        <Text variant={isDesktop ? 'large-accent' : 'medium-default'}>
                            {subtitle}
                        </Text>
                    </Box>
                ) : null}
            </div>
            <Inline flexDirection="column" alignItems={isDesktop ? 'end' : 'start'}>
                <Inline space={12}>
                    {topAction ? topAction : null}
                    {entitledCTA ? (
                        entitledCTA
                    ) : (
                        <Button variant="primary" onClick={() => setShowPlanUpdateModal(true)}>
                            {ctaCopy}
                        </Button>
                    )}
                </Inline>
            </Inline>
            {showPlanUpdateModal && (
                <PricingPlanModalWrapper
                    isOpen
                    onDismiss={() => setShowPlanUpdateModal(false)}
                    planUpgradeSource={planUpgradeSource}
                    pageOrigin={pageOrigin}
                />
            )}
        </Inline>
    )
}
