import type {FC, ReactElement} from 'react'

import {Check} from '@pleo-io/telescope-icons'

import * as s from './step-indicators.styles'

import type {StepProps} from '../wizard'

interface StepIndicatorsProps {
    currentIndex: number
    steps: ReactElement<StepProps>[]
    isCurrentStepDone?: boolean
    stepSize?: number
}

const StepIndicators: FC<StepIndicatorsProps> = ({
    currentIndex,
    steps,
    isCurrentStepDone = false,
    stepSize,
}) => {
    const getIsStepDone = (index: number) =>
        index < currentIndex || (isCurrentStepDone && index === currentIndex)

    return (
        <s.StepsOuterWrapper
            steps={steps.length}
            stepSize={stepSize}
            data-generic-ui="wizard-step-indicators"
        >
            <s.Line />
            <s.StepsWrapper>
                {steps.map(({props: {label}}, index) => {
                    const isStepDone = getIsStepDone(index)

                    return (
                        <s.StepWrapper
                            key={index}
                            data-testid={`wizard-step-${index}${isStepDone ? '-done' : ''}`}
                        >
                            <s.Step current={index === currentIndex} done={isStepDone}>
                                <Check size={16} />
                            </s.Step>
                            {label && (
                                <s.StepLabel
                                    current={index === currentIndex}
                                    data-testid={`step-indicator-${label}`}
                                    stepSize={stepSize}
                                >
                                    {label}
                                </s.StepLabel>
                            )}
                        </s.StepWrapper>
                    )
                })}
            </s.StepsWrapper>
        </s.StepsOuterWrapper>
    )
}

export default StepIndicators
