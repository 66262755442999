import {useMemo, useState} from 'react'

import * as tracking from '@product-web/analytics'
import {PlanUpgradeSource} from '@product-web/api-types/plan'
import {PricingPlanModalWrapper} from '@product-web/paywall/pricing-plan-modal/pricing-plan-modal-wrapper'

import PromoCard from './promo-card'
import {getMessage} from './promo-messages'

const PromoCardContainer = () => {
    const message = useMemo(getMessage, [])
    const [isModalOpen, setIsModalOpen] = useState(false)

    const clickWithTracking = () => {
        tracking.promoCardActioned({
            action: 'viewed',
            message,
            placement: 'sidebar',
        })

        setIsModalOpen(true)
    }
    const dismissModalWithTracking = (didCompleteAction?: boolean) => {
        tracking.promoCardActioned({
            action: didCompleteAction ? 'completed' : 'abandoned',
            message,
            placement: 'sidebar',
        })
        setIsModalOpen(false)
    }

    return (
        <>
            <PromoCard onCardClicked={clickWithTracking}>{message}</PromoCard>
            <PricingPlanModalWrapper
                isOpen={isModalOpen}
                onDismiss={dismissModalWithTracking}
                planUpgradeSource={PlanUpgradeSource.PROMO_CARD_FLOW}
                pageOrigin="promo"
                pageSection="promo-card"
            />
        </>
    )
}

export default PromoCardContainer
