import styled, {css, keyframes} from 'styled-components'

import {tokens} from '@pleo-io/telescope'

import {breakpoints} from '@product-web/styles/theme'

const defaultStepSize = 100

const bounceIn = keyframes`
    from,
    20%,
    40%,
    60%,
    80%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        transform: scale3d(0.3, 0.3, 0.3);
    }

    20% {
        transform: scale3d(1.7, 1.7, 1.5);
    }

    40% {
        transform: scale3d(0.9, 0.9, 0.9);
    }

    60% {
        transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
        transform: scale3d(0.97, 0.97, 0.97);
    }

    to {
        transform: scale3d(1, 1, 1);
    }
`

export const StepLabel = styled.div<{current?: boolean; stepSize?: number}>`
    font-weight: ${tokens.fontWeightMedium};
    font-size: 12px;
    margin-top: ${tokens.spacing8};
    white-space: nowrap;
    color: ${({current}) => (current ? tokens.colorContentStatic : tokens.colorContentStaticQuiet)};
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: ${({stepSize}) => stepSize ?? defaultStepSize}px;

    @media (max-width: ${breakpoints.tabletUp}) {
        display: none;
    }
`

export const StepWrapper = styled.div`
    display: flex;
    width: 0;
    flex-direction: column;
    align-items: center;

    &:first-child ${StepLabel} {
        align-self: flex-start;
        margin-left: calc(-1 * ${tokens.spacing10});
    }

    &:last-child ${StepLabel} {
        align-self: flex-end;
        margin-right: calc(-1 * ${tokens.spacing10});
    }

    @media (min-width: ${breakpoints.mobileLrgUp}) {
        &:first-child ${StepLabel} {
            align-self: center;
            margin-left: 0;
        }

        &:last-child ${StepLabel} {
            align-self: center;
            margin-right: 0;
        }
    }
`

export const Step = styled.div<{current?: boolean; done?: boolean}>`
    box-sizing: border-box;
    display: flex;
    border-radius: ${tokens.circle};
    transition: ${tokens.smoothInOut};
    transition-property: all;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
    font-size: 10px;
    color: ${({done}) => (done ? tokens.colorContentStaticInverse : tokens.colorBackgroundStatic)};
    border: 2px solid ${tokens.colorBorderInteractive};
    border-color: ${({current, done}) =>
        current || done ? tokens.colorContentStatic : tokens.colorBorderInteractive};
    background-color: ${({done}) =>
        done ? tokens.colorBackgroundStaticInverse : tokens.colorBackgroundStatic};
    ${({done}) =>
        done &&
        css`
            animation-name: ${bounceIn};
            animation-duration: 1000ms;
            animation-fill-mode: both;
        `}
`

export const StepsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media (max-width: ${breakpoints.tabletUp}) {
        margin: 0 auto;
        max-width: calc(100% - 22px);
    }
`
export const StepsOuterWrapper = styled.div<{steps?: number; stepSize?: number}>`
    margin: 0 auto;
    padding: ${tokens.spacing32} 0;

    ${({steps, stepSize}) =>
        steps &&
        css`
            max-width: ${steps * (stepSize ?? defaultStepSize)}px;
        `}
`

export const Line = styled.div`
    margin-bottom: calc((-1 * ${tokens.spacing14}));
    border-bottom: 2px solid ${tokens.colorBorderInteractive};
`
