import type {ComponentProps} from 'react'

import {useFlags, useFlagsLoaded} from '@product-web/flags'

import {PricingPlanModal} from './pricing-plan-modal'

import {PlanSelectionModal} from '../plan-selections/plan-selection-modal'

export const PricingPlanModalWrapper = (props: ComponentProps<typeof PlanSelectionModal>) => {
    const {pricing3PlanModal} = useFlags()
    const flagsLoaded = useFlagsLoaded()

    if (!flagsLoaded || !props.isOpen) {
        return null
    }

    if (pricing3PlanModal) {
        return <PricingPlanModal {...props} />
    }

    return <PlanSelectionModal {...props} />
}
