import {t, Trans} from '@lingui/macro'
import React from 'react'
import {useState} from 'react'
import {Link as RouterLink} from 'react-router-dom'
import styled, {css} from 'styled-components'

import {
    Avatar,
    Badge,
    Button,
    Callout,
    Inline,
    LoadingPage,
    Stack,
    Text,
    tokens,
} from '@pleo-io/telescope'
import {CalendarRange, Tag} from '@pleo-io/telescope-icons'

import {PlanUpgradeSource} from '@product-web/api-types/plan'
import {isTRPCClientError} from '@product-web/bff-client/errors'
import {defineClientContext} from '@product-web/bff-client/operational-context'
import {useFlags} from '@product-web/flags'
import {PricingPlanModalWrapper} from '@product-web/paywall/pricing-plan-modal/pricing-plan-modal-wrapper'
import {pxBreakpoints} from '@product-web/styles/theme'
import {containerQuery} from '@product-web/telescope-lab/container-queries/container'
import {useToaster} from '@product-web/toaster'
import {useIsOrganizationContext} from '@product-web/user'
import {useIsMultiEntityBudgetsEnabled} from '@product-web-features/budgets/budget-feature-flags'

import {TagBudgetDetailsHeader} from './tag-budget-details-header'
import {TagBudgetReviewersPopover} from './tag-budget-reviewers-popover'

import {bff} from '../../bff'
import BudgetExpenses from '../../components/budget-expenses-list'
import type {TagBudget, TagBudgetDetails, Tags} from '../../index.bff'
import {getFormattedDates} from '../../lib/helpers'
import {getDemoBudgetMocks} from '../../lib/mocks'
import type {BudgetDrawerTabValues, MockedTag} from '../../lib/types'
import type {BudgetExpenseData} from '../../lib/types'
import {useTagBudgetTracking} from '../../lib/use-tag-budget-tracking'
import {TagBudgetTracker} from '../tag-budget-tracker'

export const StyledIconText: React.FC<{label: string; icon: JSX.Element}> = ({label, icon}) => (
    <Inline alignY="center">
        {icon}
        <Text variant="medium-default" weight="regular" color="shade900">
            {label}
        </Text>
    </Inline>
)

export const TagBudgetDetailsDrawer: React.FC<{
    activeBudgetId: string
    onClose: () => void
    shouldShowPlanUpgrade?: boolean
    setTabValue: React.Dispatch<React.SetStateAction<BudgetDrawerTabValues>>
    tabValue: BudgetDrawerTabValues
}> = ({activeBudgetId, onClose, shouldShowPlanUpgrade, setTabValue, tabValue}) => {
    const [showChangePlanModal, setShowChangePlanModal] = useState(false)
    const {showToast} = useToaster()
    const DEMO_BUDGET_MOCKS = getDemoBudgetMocks()
    const isDemoBudget = !!activeBudgetId && activeBudgetId === 'demo'

    const isOrganization = useIsOrganizationContext()
    const {isEnabled: hasMultiEntityFlag} = useIsMultiEntityBudgetsEnabled()
    const isMultiEntity = isOrganization && hasMultiEntityFlag

    const {data: userPermissionsData, isFetched: isFetchedUserPermissions} =
        bff.tagBudgetDetails.getUserPermissions.useQuery()

    const {
        data: budgetExpenses,
        fetchNextPage,
        isFetchingNextPage,
        hasNextPage,
        refetch,
        isLoading: isLoadingExpensesList,
        isError,
    } = bff.budgetExpensesList.getBudgetExpense.useInfiniteQuery(
        {
            budgetId: activeBudgetId,
        },
        {
            getNextPageParam: (_lastPage, allPages) => {
                const total = allPages[0].total ?? 0
                const results = allPages.flatMap((page) => page.budgetExpenses) ?? []

                if (results.length < total) {
                    return allPages.length
                }

                return undefined
            },
            keepPreviousData: true,
            enabled: Boolean(activeBudgetId) && !isDemoBudget && tabValue === 'payments',
        },
    )
    const totalPages = budgetExpenses?.pages[0].total ?? 0
    const budgetExpensesList = budgetExpenses?.pages?.flatMap((page) => page.budgetExpenses)

    const hasOwnerAccess =
        (isFetchedUserPermissions && userPermissionsData?.hasOwnerAccess) ?? false

    const {data: tagBudgetData, isLoading} = bff.tagBudgetDetails.getTagBudgetById.useQuery(
        {budgetId: activeBudgetId!},
        {
            enabled: Boolean(activeBudgetId) && !isDemoBudget,
            onError(error) {
                if (isTRPCClientError(error) && error.data?.httpStatus === 404) {
                    onClose() // Close drawer, budget is invalid
                } else {
                    reportError(error)
                    showToast(t`Failed to fetch data`, {level: 'error'})
                }
            },
        },
    )

    const {data: tagsData} = bff.tagBudgetDetails.getBudgetTags.useQuery(
        {budgetId: activeBudgetId!},
        {
            enabled: Boolean(activeBudgetId) && !isDemoBudget,
            onError(error) {
                if (isTRPCClientError(error) && error.data?.httpStatus === 404) {
                    onClose() // Close drawer, budget is invalid
                } else {
                    reportError(error)
                    showToast(t`Failed to fetch data`, {level: 'error'})
                }
            },
        },
    )

    const tagBudget = isDemoBudget ? DEMO_BUDGET_MOCKS.budget : tagBudgetData
    const tags = isDemoBudget ? DEMO_BUDGET_MOCKS.tags : tagsData
    const tagGroups = tags?.map((tag) => tag.value) ?? []

    const {
        id,
        name,
        startDate,
        endDate,
        percentageSpent,
        isUpcoming,
        isClosed,
        noEndDate,
        reviewerUserIds = [],
    } = (tagBudget as TagBudgetDetails) ?? {}

    const listRef = React.useRef<HTMLDivElement | null>(null)

    const onScroll = async () => {
        const listElement = listRef?.current
        if (!listElement) {
            return
        }

        if (
            !isFetchingNextPage &&
            hasNextPage &&
            listElement.clientHeight + listElement.scrollTop + 600 >= listElement.scrollHeight
        ) {
            await fetchNextPage()
        }
    }

    const {invoicesInBudget} = useFlags()
    if (isLoading && !isDemoBudget) {
        return (
            <DetailsWrapper justifyContent="center">
                <LoadingPage />
            </DetailsWrapper>
        )
    }
    if (!tagBudget) {
        return null
    }
    return (
        <>
            <DetailsWrapper stretch data-testid={`tag-budget-details-${id}`}>
                <TagBudgetDetailsHeader
                    tabValue={tabValue}
                    setTabValue={setTabValue}
                    budget={tagBudget as TagBudgetDetails}
                    hasOwnerAccess={hasOwnerAccess}
                    tagGroups={tagGroups}
                    onClose={onClose}
                    isDemoBudget={isDemoBudget}
                />
                <ContentWrapper>
                    {isDemoBudget && (
                        <Stack stretch marginBottom={40}>
                            <Callout variant="info">
                                <Callout.Text align="left">
                                    <Trans>This is a demo budget</Trans>
                                </Callout.Text>
                            </Callout>
                        </Stack>
                    )}
                    <Stack space={4} marginBottom={40} justifyItems="center">
                        <Text variant="3xlarge-accent" weight="regular" color="shade800">
                            {name}
                        </Text>
                        <Text variant="medium-default" weight="medium" color="shade700">
                            {t`${percentageSpent}% spent`}
                        </Text>
                    </Stack>
                    {tabValue === 'general' && (
                        <>
                            <TagBudgetTracker budget={tagBudget as TagBudget} isDrawer />
                            <BudgetOverview
                                {...{
                                    startDate,
                                    endDate,
                                    noEndDate,
                                    isClosed,
                                    isUpcoming,
                                    shouldShowPlanUpgrade,
                                    isDemoBudget,
                                    setShowChangePlanModal,
                                    tags,
                                }}
                            />
                        </>
                    )}
                    {tabValue === 'payments' && invoicesInBudget && (
                        <BudgetExpenses
                            onScroll={onScroll}
                            fetching={isLoadingExpensesList}
                            budgetExpenses={budgetExpensesList as Array<BudgetExpenseData>}
                            total={totalPages}
                            itemCount={budgetExpensesList?.length ?? 0}
                            nextPage={fetchNextPage}
                            errorFetchingBudgetExpenses={isError}
                            refetchBudgetExpenses={async () => await refetch()}
                        />
                    )}
                </ContentWrapper>
                {!isDemoBudget && tabValue === 'general' && (
                    <TagBudgetReviewersPopover
                        reviewers={reviewerUserIds}
                        isMultiEntity={isMultiEntity}
                    />
                )}
            </DetailsWrapper>
            {showChangePlanModal && (
                <PricingPlanModalWrapper
                    isOpen
                    onDismiss={() => setShowChangePlanModal(false)}
                    planUpgradeSource={PlanUpgradeSource.BUDGETS_EMPTYSTATE_FLOW}
                    pageOrigin={'budgets'}
                />
            )}
        </>
    )
}

const BudgetOverview = ({
    startDate,
    endDate,
    noEndDate,
    isClosed,
    isUpcoming,
    shouldShowPlanUpgrade,
    isDemoBudget,
    setShowChangePlanModal,
    tags,
}: {
    startDate: string | undefined
    endDate: string | undefined
    noEndDate: boolean
    isClosed: boolean
    isUpcoming: boolean
    shouldShowPlanUpgrade: boolean | undefined
    isDemoBudget: boolean
    setShowChangePlanModal: React.Dispatch<React.SetStateAction<boolean>>
    tags: Tags[] | MockedTag[] | undefined
}) => {
    const {data: hasTags} = bff.tagBudgetDetails.getHasTagsInfo.useQuery(undefined, {
        enabled: isDemoBudget,
        trpc: {
            context: defineClientContext({
                skipBatch: true,
            }),
        },
    })
    const tracking = useTagBudgetTracking()

    const getStatusText = () => {
        if (isClosed) {
            return t`Closed`
        } else if (isUpcoming) {
            return t`Upcoming`
        }

        return t`Active`
    }

    const statusText = getStatusText()
    const noEndDatePrefix = noEndDate && statusText === 'Upcoming' ? t`Starting` : t`Started`
    const datePrefix = noEndDate ? noEndDatePrefix : ''
    const datesText = getFormattedDates(startDate, endDate)

    return (
        <Stack space={32} marginTop={48}>
            <Inline space={8} alignItems="center">
                <StyledIconText
                    label={`${datePrefix} ${datesText}`}
                    icon={<CalendarRange size={16} color={tokens.shade900} mr={8} />}
                />
                <Badge loud variant={isClosed ? 'neutral' : 'info'}>
                    {statusText}
                </Badge>
            </Inline>
            {tags?.map((tag) => (
                <StyledIconText
                    key={tag.value}
                    label={tag.label + (tag.companyName ? ` · ${tag.companyName}` : '')}
                    icon={<Tag size={16} color={tokens.shade900} mr={8} />}
                />
            ))}
            {isDemoBudget && (
                <Stack space={48} paddingBottom={40}>
                    <StyledIconText
                        label={t`Reviewer: Alice Newland`}
                        icon={<Avatar outlined name={t`Alice Newland`} size={24} mr={8} />}
                    />
                    {hasTags && !shouldShowPlanUpgrade && (
                        <Stack space={0} justifyItems="center">
                            <Button
                                variant="primary"
                                as={RouterLink}
                                to="/budgets/create"
                                onClick={() =>
                                    tracking.demoBudgetActioned({
                                        action: 'clicked',
                                        source: 'create_budget_button',
                                    })
                                }
                            >
                                <Trans>Create budget</Trans>
                            </Button>
                        </Stack>
                    )}
                    {shouldShowPlanUpgrade && (
                        <Stack space={0} justifyItems="center">
                            <Button variant="primary" onClick={() => setShowChangePlanModal(true)}>
                                <Trans>Get Budgets</Trans>
                            </Button>
                        </Stack>
                    )}
                </Stack>
            )}
        </Stack>
    )
}

const DetailsWrapper = styled(Stack)`
    height: 100%;
    width: 590px;
    ${containerQuery(
        {maxWidth: pxBreakpoints.tabletMedUp},
        css`
            width: 100%;
            min-width: 372px;
        `,
    )}
    ${containerQuery(
        {maxWidth: pxBreakpoints.mobileLrgUp},
        css`
            min-width: 0;
        `,
    )}
`

const ContentWrapper = styled(Stack)`
    padding-block: ${tokens.spacing20};
    padding-inline: ${tokens.spacing44};
    background-color: ${tokens.shade000};
    height: calc(100vh - 60px);
    overflow-y: scroll;
`
